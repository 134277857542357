import React from 'react';
import Person from './Person/Person';

const persons = props => props.persons.map(person =>  
    <Person 
      name={person.name} 
      age={person.age}
      key={person.id}
      click={() => props.clicked(person.id)}
      changed={(event) => props.changed(event, person.id)}
    >
    </Person>
);

export default persons;