import React from 'react';
import Radium from 'radium';

const cockpit = props => {
  let warningStyle = {};
  let warningText = 'Enough persons';
  let style = {
    backgroundColor: 'green',
    color: 'white',
    font: 'inherit',
    border: '1px solid blue',
    padding: '8px',
    ':hover': {
      backgroundColor: 'lightgreen',
      color: 'black'
    }
  }

  if ( props.showPersons ) {   
    style['backgroundColor'] = 'red';
    style['color'] = 'white';
    style[':hover'] = {
      backgroundColor: 'salmon',
      color: 'black'
    };      
  }
  if (props.persons.length <= 2) {
    warningStyle = {
      color: 'red'
    }
    warningText = 'A few persons left ...';
  } 
  if (props.persons.length === 1) {
    warningStyle = {
      color: 'red',
      fontWeight: 'bold'
    }
    warningText = 'Last person!!!!';
  }

  return (
    <div>
      <h1>
        Hi there!!! This is my first React App
      </h1>
      <p style={warningStyle}>
        {warningText}
      </p>
      <button
        style={style} 
        onClick={() => props.clicked()}
        >Toggle Persons</button>
    </div>
   );
};

export default Radium(cockpit);