import React from 'react';

const withClass = (WrappedComponent, passedClass) => {
  return (props) => (
    <div className={passedClass}>
      <WrappedComponent {...props} />
    </div>
  )
};

export default withClass;