import React, { Component } from 'react';
import classes from './Person.module.css';
import withClass from '../../../hoc/withClass';

class Person extends Component {
  render() {
    return [
      <p key='id1' onClick={this.props.click}><strong>I'am  {this.props.name} and am {this.props.age} years old!!!</strong></p>,
      <p key='id2'>{this.props.children}</p>,
      <input key='id3' onChange={this.props.changed} value={this.props.name}/>
    ]
  }
}

export default withClass(Person, classes.Person);