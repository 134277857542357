import React, { Component } from 'react';
import Persons from '../components/Persons/Persons';
import Cockpit from '../components/Cockpit/Cockpit';
import Radium from 'radium';
import './App.css';

class App extends Component {
  state = {
      persons: [
        {name: 'Max', age: 28, id: 'a1w2e3'},
        {name: 'Manu', age: 29, id: 's2s1s3'},
        {name: 'Stephanie', age: 26, id: 'qwe212'},
      ],
      showPersons: false,
      changeCounter: 0
    };

  showPersonsHandler() {
    const showPersons = this.state.showPersons;
    this.setState({
      showPersons: !showPersons
    });
  }

  deletePersonHandler(id) {
    const personIndex = this.state.persons.findIndex(person => person.id === id);
    const persons = [...this.state.persons];
    persons.splice(personIndex, 1);
    this.setState({
      persons: persons
    });
  }

  changedNameHandler(event, id) {
    const personIndex = this.state.persons.findIndex(person => person.id === id);
    const persons = [...this.state.persons];
    const modifiedPerson = persons[personIndex];
    modifiedPerson.name = event.target.value;
    persons[personIndex] = modifiedPerson;
    this.setState((prevState, props) => {
      return {
        persons: persons,
        changeCounter: prevState.changeCounter + 1
      }      
    });
  }

  render() {
    let personsToRender = null;
    if ( this.state.showPersons ) {
      personsToRender = <Persons 
        persons={this.state.persons} 
        clicked={this.deletePersonHandler.bind(this)} 
        changed={this.changedNameHandler.bind(this)}
      />            
    }

    return (
      <div className='App'>
        <Cockpit 
          persons = {this.state.persons}
          clicked = {this.showPersonsHandler.bind(this)}
          showPersons = {this.state.showPersons}
        />
        {personsToRender}
      </div>
    )  
  }
};

export default Radium(App);